'use client';

import React from 'react';

import { Skeleton } from '@mui/material';

import PostContent from 'components/blog/post-content/post-content';

import type { IBanner } from 'services/user.service';
import type { IPost } from 'types/model.post';

import styles from './seo-blog-post.module.scss';

import 'swiper/css';

interface ISeoBlogPostPageProps {
  bannerListData: IBanner[];
  post: IPost;
}

const SeoBlogPost: React.FC<ISeoBlogPostPageProps> = ({
  post,
  bannerListData,
}) => (
  <main className={styles.page__post}>
    {post ? (
      <PostContent bannerListData={bannerListData} blog={false} post={post} />
    ) : (
      <div className={styles.skeleton}>
        <Skeleton height='400px' variant='rounded' />
        <Skeleton height='20px' variant='rounded' width='200px' />
        <Skeleton height='400px' variant='rounded' />
      </div>
    )}
  </main>
);

export default SeoBlogPost;
